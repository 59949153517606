<template>
  <div class="home">
    <x-header back>删除的项目</x-header>
    <x-content v-if="loading" style="height:100%;"
               :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
      <bounce-loading></bounce-loading>
    </x-content>
    <x-content v-else :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
      <!-- <div class="search_warp">
          <el-row class="list-control_warp">
              <el-col :span="24">
                  <el-col :span="6">
                      <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入实验名称">
                          <template slot="suffix">
                              <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                          </template>
                      </el-input>
                  </el-col>
                  &nbsp;
                  <div style="display: flex; justify-content: flex-end;">
                      <el-button style="" size="medium" type="primary" @click="openDialog" icon="base-icon-plus">
                              <span>新建实验</span>
                      </el-button>
                  </div>
              </el-col>
          </el-row>
      </div> -->
      <div style="margin-bottom:10px;">
        <el-button icon="el-icon-refresh-right" @click="cancelBatchDelete"> 批量还原</el-button>
      </div>
      <x-card>
        <el-table
            :data="groupList"
            stripe
            @selection-change="handleSelectionChange"
            style="width: 100%">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
                        prop="projectCode"
                        label="项目编号">
                    </el-table-column>
          <el-table-column
              prop="groupName"
              label="项目名称">
          </el-table-column>
          <!-- <el-table-column
              prop="topicHead"
              label="创建人">
          </el-table-column>-->
<!--          <el-table-column-->
<!--              label="负责人">-->
<!--            <template slot-scope="scope">-->
<!--              {{ scope.row.groupManagerList ? scope.row.groupManagerList.join(",") : "" }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              prop="countGroupUser"-->
<!--              label="成员数量">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              prop="countExperiment"-->
<!--              label="实验数量">-->
<!--          </el-table-column>-->
          <el-table-column
              width="300"
              label="操作">
            <template slot-scope="scope">
              <el-button class="group_operate" size="mini" @click="cancelDelete(scope)" type="success">还原</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-warp">
          <el-pagination
              @current-change="listGroup"
              :hide-on-single-page="total<pageSize"
              background
              layout="total,prev, pager, next"
              :total="total"
              :page-size="pageSize"
              :current-page.sync="page"
          ></el-pagination>
        </div>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import {GetDeleteList, CancelDelete} from "@/service/user";
export default {
  name: 'groupDelete',
  data() {
    return {
      loading: false,
      saveLoading: false,
      savePermissionLoading: false,
      page: 1,
      total: 0,
      pageSize: 10,
      searchText: "",
      groupList: [],
      selectGroup: [],
    }
  },
  created() {
    this.status = this.$route.params.status || '';
    this.listGroup();
  },
  watch: {
    // '$route.params.status':{
    // 	handler:function (newVal){
    //         if(newVal){
    //             this.status = newVal
    //             this.listExperiment();
    //         }
    // 	},
    // 	immediate:true
    // }
  },
  methods: {
    search() {
      this.page = 1;
      this.listGroup();
    },
    goExperimentEdit() {
      this.$router.push('/manager/experiment/edit/' + this.form.note + '/' + this.form.formTemplate + '/1/0')
    },
    listGroup() {
      this.loading = true;
      GetDeleteList(this.page, this.pageSize).then(e => {
        //   let data = this.APIParse(e);
        this.groupList = e.data.records;
       // console.log(this.listGroup)
        this.total = e.data.total || 0;

      }).catch(e => {
        this.$message.error(e.message)
      }).finally(e => {
        this.loading = false;
      })
    },
    handleSelectionChange(val) {
      this.selectGroup = val;
    },
    cancelDelete(scope) {
      this.$confirm('确定还原这个项目吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        CancelDelete(scope.row.id).then(e => {
          let data = this.APIParse(e);
          if (data) {
            this.$message.success('还原项目成功')
            this.listGroup();
          } else {
            this.$message.error('还原项目失败，请重试')
          }
        }).catch(ex => {
          this.$message.error('还原项目失败，请重试')
        })
      }).catch(() => {
      });
    },
    cancelBatchDelete() {
      if (this.selectGroup.length == 0) {
        this.$message.warning("请选择项目！")
        return
      }
      this.$confirm('确定还原所选的这些项目吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        CancelDelete(this.selectGroup.map(e => {
          return e.id
        }).join(",")).then(e => {
          let data = this.APIParse(e);
          if (data) {
            this.$message.success('还原项目成功')
            this.listGroup();
          } else {
            this.$message.error('还原项目失败，请重试')
          }
        }).catch(ex => {
          this.$message.error('还原项目失败，请重试')
        })
      }).catch(() => {
      });
    },
    changeShow() {
      this.page = 1
      this.listGroup();
    },
  }
}
</script>
<style lang="scss" scoped>
.group_operate + .group_operate {
  margin-left: 15px;
}

.Width-100 {
  width: 100%
}

.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.search_warp {
  width: 100%;
  margin: 16px 0px;
}

.save_btn_warp {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-warp {
  text-align: center;
  margin-top: 20px;
}

.mySign {
  border: 1px dashed #000;
}
</style>